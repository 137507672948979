import React, { useEffect } from "react";
import rocketmanTransport from "../../services/RocketmanTransport";
import translate from "../../services/Translation/lang";
import { currencyFormatter } from "../../services/Helpers/NumberFormatter";
import Currency from "../Currency/Currency";

export default function JackpotWinners() {
  const data = rocketmanTransport.jackpotWinsData;

  const formatDateTime = (dateString) => {
    const winnerDate = new Date(dateString);

    // Get the date in the desired format (e.g., 28-Aug-2024)
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const dateFormatted = winnerDate
      .toLocaleDateString("en-GB", options)
      .replace(/ /g, "-");

    // Get the time in the desired format (e.g., 07:05)
    const timeFormatted = winnerDate.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });

    return { dateFormatted, timeFormatted };
  };

  const truncateText = (element) => {
    const lineHeight = parseFloat(
      window.getComputedStyle(element).lineHeight
    );
    const maxHeight = lineHeight * 2; // Maximum height for two lines
    let text = element.innerText;

    while (element.scrollHeight > maxHeight && text.length > 0) {
      text = text.slice(0, -1);
      element.innerText = text + "...";
    }
  };

  useEffect(() => {
    const elements = document.querySelectorAll(".jackpot-winners-td-shop");
    elements.forEach((element) => truncateText(element));
  }, [data]);

  return (
    <div className="slide jackpot-winners">
      <p className="slider-text-big mb-4">{translate("jackpot_winners")}</p>
      <div className="jackpot-winners-table">
        <div className="jackpot-winners-thead">
          <div className="jackpot-winners-th">{translate("ticket_id")}</div>
          <div className="jackpot-winners-th">{translate("type")}</div>
          <div className="jackpot-winners-th">{translate("amount")}</div>
          <div className="jackpot-winners-th">{translate("time")}</div>
          <div className="jackpot-winners-th">{translate("shop")}</div>
        </div>
        <div className="jackpot-winners-tbody">
          {data && data.length > 0 ? (
            data.map((winner, index) => {
              const { dateFormatted, timeFormatted } = formatDateTime(
                winner.JackPotTime
              );
              return (
                <div className="jackpot-winners-tr" key={index}>
                  <div className="jackpot-winners-td">{winner.TicketID}</div>
                  <div className="jackpot-winners-td jackpot-winners-td-type">{winner.JackpotName}</div>
                  <div className="jackpot-winners-td text-right">
                    <span className="jackpot-winners-td-amount">
                      {currencyFormatter(winner.JackPotValue)}
                      <Currency className="ml-1">
                        {rocketmanTransport.myCurrency}
                      </Currency>
                    </span>
                  </div>
                  <div className="jackpot-winners-td">
                    <div>{dateFormatted}</div>
                    <div>{timeFormatted}</div>
                  </div>
                  <div className="jackpot-winners-td">
                    <div className="jackpot-winners-td-shop">{winner.Shop}</div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="jackpot-winners-tr">
              <div className="jackpot-winners-td"></div>
              <div className="jackpot-winners-td"></div>
              <div className="jackpot-winners-td"></div>
              <div className="jackpot-winners-td"></div>
              <div className="jackpot-winners-td"></div>
            </div>
          )}
        </div>
        <div className="jackpot-winners-tfooter">
          <div className="jackpot-winners-tr">
            <div className="jackpot-winners-td"></div>
            <div className="jackpot-winners-td"></div>
            <div className="jackpot-winners-td"></div>
            <div className="jackpot-winners-td"></div>
            <div className="jackpot-winners-td"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
