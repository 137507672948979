export function removeEmpty(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}

export function sortObjectByKeyDesc(obj) {
  let result = {};
  Object.keys(obj).sort((a, b) => Number(b) - Number(a)).forEach(key => {
    result[key] = obj[key];
  });

  return result;
}

export function sliceObject(myObject, start, end) {
  let result = {};
  const myObjLength = Object.keys(myObject).length;
  const endConditional = myObjLength < end ? myObjLength : end;

  const sliced = Object.keys(myObject).reverse().slice(start, endConditional);

  const filtered = Object.keys(myObject).filter(function (el) {
    return !sliced.includes(el);
  });

  filtered.forEach(key => {
    result[key] = myObject[key];
  });

  return result;
}

export function transformedData(data) {
  return Object.entries(data).map(([key, value]) => ({ [key]: value }));
}