import React from "react";
import rocketmanTransport from "../services/RocketmanTransport";

const PrimaryButton = (props) => {
  return (
    <button
      disabled={props.disabled}
      className={"primary-button " + (rocketmanTransport.r7css ? "r7css" : "")}
      onClick={props.onClick}
    >
      {props.children}
      {props.title}
    </button>
  );
};

export default PrimaryButton;
