import React from "react";
import translate from "../../services/Translation/lang";

export default function Multibet() {
  return (
    <div className="slide multibet">
      <p className="slider-text-big">
        {translate("play_multibets")} <strong>{translate("multibets")}</strong>{" "}
        {translate("multibets_paragraph_1")}
      </p>
      <p className="slider-text-small">{translate("multibets_paragraph_2")}</p>
      <p className="slider-text-small">{translate("multibets_paragraph_3")}</p>
    </div>
  );
}
