import React, { createContext, useContext, useState } from 'react';
import rocketmanTransport from '../../services/RocketmanTransport';

const RocketmanTransportContext = createContext();

export const useRocketmanTransport = () => {
  return useContext(RocketmanTransportContext)
}

export const RocketmanContextProvder = ({ children }) => {
  const [currRound, setCurrRound] = useState();
  const [myBetsByRound, setMyBetsByRound] = useState([]);
  const [gameState,setGameState] = useState("initialState");
  const [mp, setMp] = useState();
  const [cashedOuts, setCashedOuts] = useState([]);
  const [endedAndCashouted, setEndedAndCashouted] = useState(false);
  const [rockedSnapped, setRocketSnapped] = useState(false);
  const [multipliersHistory, setMultipliersHistory] = useState([]);
  const [allPlayerBets, setAllPlayerBets] = useState([]);
  //const [myBetsList, setMyBetsList] = useState([]);
  const [avatarId, setAvatarId] = useState(0);
  const [username, setUsername] = useState("");
  const [cashoutTick, setCashoutTick] = useState(false);
  const [jackpotData, setJackpotData] = useState({});
  const [bothButtonsActive, setBothButtonsActive] = useState(false);
  const [freeBetsCount, setFreeBetsCount] = useState(0);
  const [freeBetsMoney, setFreeBetsMoney] = useState(0);
  const [promoBalance, setPromoBalance] = useState(rocketmanTransport.promoBalance);
  const [showBonusHeader, setShowBonusHeader] = useState(false);
  const [uncreditedWins, setUncreditedWins] = useState(0);
  const [insuranceSnapped, setInsuranceSnapped] = useState(false);

  window.setCurrRound = setCurrRound;
  window.setMyBetsByRound = setMyBetsByRound;
  window.gameState = setGameState;
  window.setMp = setMp;
  window.setCashedOuts = setCashedOuts;
  window.setRocketSnapped = setRocketSnapped;
  window.setInsuranceSnapped = setInsuranceSnapped;
  window.setEndedAndCashouted = setEndedAndCashouted;
  window.setMultipliersHistory = setMultipliersHistory;
  window.setAllPlayerBets = setAllPlayerBets;
  //window.setMyBetsList = setMyBetsList;
  window.setAvatarId = setAvatarId;
  window.setUsername = setUsername;
  window.setCashoutTick = setCashoutTick;
  window.setJackpotData = setJackpotData;
  window.setBothButtonsActive = setBothButtonsActive;
  window.setFreeBetsCount = setFreeBetsCount;
  window.setFreeBetsMoney = setFreeBetsMoney;
  window.setShowBonusHeader = setShowBonusHeader;
  window.setPromoBalance = setPromoBalance;
  window.setUncreditedGlobalWins =  (change) => {
    setUncreditedWins(change)
  };

  return <RocketmanTransportContext.Provider 
      value={{rockedSnapped, endedAndCashouted, cashedOuts, avatarId, username, gameState, multipliersHistory, jackpotData, showBonusHeader, allPlayerBets, cashoutTick, mp, myBetsByRound, /* myBetsList, */currRound, bothButtonsActive, 
        freeBetsCount, freeBetsMoney, promoBalance, uncreditedWins, insuranceSnapped}} >{children}
    </RocketmanTransportContext.Provider>
}
