import * as React from "react";

function ElbetLogoLoader(props) {
  return (
    <svg
      width={props.width || 96}
      height={props.height || 95}
      viewBox="0 0 96 95"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.255 7.424a19.379 19.379 0 00-9.989 5.317l-3.567 3.566-8.79-.089a3.441 3.441 0 00-2.539 1.006l-10.349 10.35c-1.084 1.083-.316 2.937 1.217 2.937l6.556-.298-2.037 2.036a3.035 3.035 0 000 4.292l20.044 20.044a3.035 3.035 0 004.292 0l2.059-2.059-.32 6.579c0 1.533 1.853 2.3 2.937 1.217l10.349-10.35a3.442 3.442 0 001.007-2.538l-.067-8.813 3.544-3.545a19.38 19.38 0 005.317-9.989l3.331-17.062c.688-3.522-2.41-6.62-5.933-5.933L68.255 7.424zm-7.202 26.865a6.883 6.883 0 109.734-9.734 6.883 6.883 0 00-9.734 9.734z"
        fill="#fff"
      />
      <path
        d="M53.764 6.813a27.683 27.683 0 017.02-5.097c-15.846-4.389-33.548-.36-46.015 12.108-18.432 18.432-18.432 48.317 0 66.75 18.433 18.432 48.317 18.432 66.75 0 12.467-12.468 16.496-30.17 12.108-46.015a27.685 27.685 0 01-5.098 7.02l-1.29 1.29c1.263 11.46-2.502 23.362-11.283 32.142-9.338 9.338-22.202 12.999-34.306 10.984a7.868 7.868 0 00-1.85-8.203 7.84 7.84 0 00-5.345-2.301 11.837 11.837 0 00-.692-8.35l3.256-3.255a3.933 3.933 0 10-5.562-5.563L28.2 61.58a11.837 11.837 0 00-8.35-.693 7.84 7.84 0 00-2.3-5.345 7.868 7.868 0 00-8.203-1.85C7.333 41.59 10.994 28.724 20.332 19.386c8.78-8.78 20.681-12.545 32.142-11.283l1.29-1.29z"
        fill="#fff"
      />
      <path
        d="M33.073 48.815L46.527 62.27c.893.893 2.34.893 3.234 0l.212-.212c.498-.498.9-1.084 1.185-1.728l1.398-3.156L38.17 42.787l-3.157 1.398a5.715 5.715 0 00-1.727 1.184l-.213.213a2.286 2.286 0 000 3.233z"
        fill="#fff"
      />
    </svg>
  )
}

export default ElbetLogoLoader;
