import rocketmanTransport from '../RocketmanTransport';
import {langArr} from './language';

let language = rocketmanTransport?.launcherLanguage || 'en';

export const initLang = () => {
  document.querySelectorAll("[data-langName]").forEach((el) => {
    let langName = el.getAttribute('data-langName');
    el.innerHTML = langArr[language][langName];
  });
}

let translate = (word) => {
  word = word.toLowerCase()
  if (word in langArr[language]) {
    return langArr[language][word];
  }
  console.error('word not translated', word);
  return '';
}

export const languageReinit = (lang) => {
  translate = null;
  language = lang || 'en';
  translate = (word) => {
    if (word in langArr[language]) {
      return langArr[language][word];
    }
    console.error('word not translated', word);
    return '';
  }
}

export default translate;
