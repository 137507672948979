import React, { useMemo, useState } from 'react'
import translate from '../../services/Translation/lang'


export default function RetailTotalBetsBadge() {

  const [totalBets, setTotalBets] = useState(0);
  window.setRetailTicketsLenght = setTotalBets;
  return useMemo( () => (
    <>
    {translate('active_bets')}
    <div className="pod-tabs-nav-badge">
      {totalBets}
    </div>
    </>
  ),[totalBets, translate('active_bets')])
}
