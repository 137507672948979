export let langArr = {};
langArr.en = {
'bet_bets_tab': 'STAKE',
'coeff': 'COEFF.',
'win': 'WIN',
'game_explanation_1': 'Choose your multiplier, and if the game multiplier exceeds your chosen multiplier, your win will be',
'game_explanation_2': 'Your Multiplier x Your Bet',
'retail_max_mp_1': 'Win up to',
'retail_max_mp_2': 'your bet!',
'play_multibets': 'Play',
'multibets': 'MULTIBETS',
'bonus_game': 'BONUS GAME',
'all_wins_doubled': 'All wins doubled!',
'multibets_paragraph_1': 'to increase your odds!',
'multibets_paragraph_2': 'You may combine bets on up to ten rounds into a single multibet and win massive payouts!',
'multibets_paragraph_3': 'Each time a round is won, winning dividend from that round is wagered on the next round, until all rounds are winning.',
'bonus_launch': 'Win x2 on all bets in the „Bonus Launch“ round!',
'multiplier_history': 'Multiplier History',
'will_start_soon': 'will start soon',
'hurry_up_and_place_your_bets': 'Hurry up and place your bets!',
'active_bets': 'Active Bets',
'shop': 'shop',
'round': 'Round',
'round_id': 'Round Id',
'loading': 'Loading',
'lost_connection': 'Lost Connection',
'jackpot_winners': 'Jackpot Winners',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.sw = {
'bet_bets_tab': 'KIASI CHA DAU',
'coeff': 'MATUMAINI',
'win': 'SHINDA',
'game_explanation_1': 'Chagua kizidishaji chako, na ikiwa kizidishaji cha mchezo kinazidi kizidishaji ulichochagua, ushindi wako utakuwa',
'game_explanation_2': 'Kizidishaji Chako x Dau Lako',
'retail_max_mp_1': 'Shinda hadi',
'retail_max_mp_2': 'dau lako!',
'play_multibets': 'Cheza',
'multibets': 'MBINU',
'bonus_game': 'MCHEZO WA BONUS',
'all_wins_doubled': 'Ushindi wote uliongezeka maradufu!',
'multibets_paragraph_1': 'ili kuongeza odds zako!',
'multibets_paragraph_2': 'Unaweza kuchanganya dau kwenye hadi raundi kumi katika multibet moja na kushinda malipo makubwa!',
'multibets_paragraph_3': 'Kila wakati duru inaposhinda, mgao wa faida kutoka kwa raundi hiyo huwekwa kwenye raundi inayofuata, hadi raundi zote zishinde.',
'bonus_launch': 'Shinda x2 kwenye dau zote kwenye raundi ya "Uzinduzi wa Bonasi"!',
'multiplier_history': 'Historia ya Vizidishaji',
'will_start_soon': 'itaanza hivi karibuni',
'hurry_up_and_place_your_bets': 'Harakisha na uweke madau yako!',
'active_bets': 'Madau Amilifu',
'shop': 'duka',
'loading': 'Inapakia',
'lost_connection': 'Muunganisho Umepotea',
'jackpot_winners': 'Jackpot Winners',
'round': 'Raundi',
'round_id': 'Kitambulisho cha raundi',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.zu = {
'bet_bets_tab': 'ISITEKI',
'coeff': 'I-COEFF.',
'win': 'WINA',
'game_explanation_1': 'Khetha okuphindayo kwakho, futhi uma okuphindayo kwakho kwegeyimu kwedlula okuphindayo kwakho okukhethile, ukuwina kwakho kuzoba',
'game_explanation_2': 'Okuphindayo Kwakho x Lokho Okubhejile',
'retail_max_mp_1': 'Wina kuze kufike',
'retail_max_mp_2': 'kulokho okubhejile!',
'play_multibets': 'Dlala',
'multibets': 'AMANINGI',
'bonus_game': 'IBHONASI UMDLALO',
'all_wins_doubled': 'Konke ukuwina kuphindwe kabili!',
'multibets_paragraph_1': 'ukwandisa amathuba akho!',
'multibets_paragraph_2': 'Ungahlanganisa ukubheja emizuliswaneni efika kweyishumi ube yi-multibet eyodwa futhi uzuze izinkokhelo ezinkulu!',
'multibets_paragraph_3': 'Ngaso sonke isikhathi uma kuwinwa umzuliswano, i-dividend yokuwina kulowo mzuliswano ibhejwa emzuliswaneni olandelayo, kuze kube yilapho yonke imizuliswano iyawina.',
'bonus_launch': 'Wina x2 kukho konke ukubheja emzuliswaneni othi "Ibhonasi Yokwethulwa"!',
'multiplier_history': 'Umlando Wokuphindayo',
'will_start_soon': 'uzoqala maduzane',
'hurry_up_and_place_your_bets': 'Shesha futhi ubheje!',
'active_bets': 'Ukubheja Okuqhubekayo',
'shop': 'isitolo',
'loading': 'Iyalayisha',
'lost_connection': 'Ulahlekelwe Uxhumano',
'jackpot_winners': 'Jackpot Winners',
'round': 'Umjikelezo',
'round_id': 'I-Id Yomjikelezo',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.xh = {
'bet_bets_tab': 'ISICHENGE',
'coeff': 'I-COEFF.',
'win': 'WINA',
'game_explanation_1': 'Khetha isiphindaphindi sakho, kwaye ukuba isiphindaphindi somdlalo siyagqitha kwisiphindaphindi sakho esikhethiweyo, ukuwina kwakho kuza kuba',
'game_explanation_2': 'Isiphindaphindi Sakho x Ukubheja Kwakho',
'retail_max_mp_1': 'Wina ukuya kufikelela',
'retail_max_mp_2': 'kukubheja kwakho',
'play_multibets': 'Dlala',
'multibets': 'IIMULTIBETS',
'bonus_game': 'IBHONASI UMDLALO',
'all_wins_doubled': 'Zonke iimpumelelo ziphindwe kabini!',
'multibets_paragraph_1': 'ukwandisa amathuba akho!',
'multibets_paragraph_2': 'Ungadibanisa ukubheja ukuya kwimijikelo elishumi kwi-multibet enye kwaye uphumelele iintlawulo ezinkulu!',
'multibets_paragraph_3': 'Ngalo lonke ixesha kuphunyelelwa umjikelo, isahlulo esiphumeleleyo kulo mjikelo sibhejwa kumjikelo olandelayo, de yonke imijikelo iphumelele.',
'bonus_launch': 'Wina i-x2 kuko konke ukubheja kumjikelo othi "Ukuqalisa Ibhonasi"!',
'multiplier_history': 'Imbali Yesiphindaphindi',
'will_start_soon': 'iza kuqalisa kungekudala',
'hurry_up_and_place_your_bets': 'Khawuleza uze ubeke ukubheja kwakho!',
'active_bets': 'Ukubheja Okusebenzayo',
'shop': 'thenga',
'loading': 'Iyalowuda',
'lost_connection': 'Unxibelelwano Olulahlekileyo',
'jackpot_winners': 'Jackpot Winners',
'round': 'Umjikelo',
'round_id': 'I-Id Yomjikelo',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.af = {
'bet_bets_tab': 'SPEELGELD',
'coeff': 'KOËFF.',
'win': 'WEN',
'game_explanation_1': 'Kies jou vermenigvuldiger en as die spelvermenigvuldiger jou gekose vermenigvuldiger oorskry, sal jy xx wen.',
'game_explanation_2': 'Jou vermenigvuldiger x jou weddenskap',
'retail_max_mp_1': 'Wen tot',
'retail_max_mp_2': 'jou weddenskap!',
'play_multibets': 'Speel',
'multibets': 'MULTIBETS',
'bonus_game': 'BONUSSPEL',
'all_wins_doubled': 'Alle oorwinnings het verdubbel!',
'multibets_paragraph_1': 'om jou kans te verhoog!',
'multibets_paragraph_2': 'Jy kan weddenskappe op tot tien rondtes in \'n enkele multiweddenskap kombineer en massiewe uitbetalings wen!',
'multibets_paragraph_3': 'Elke keer as \'n rondte gewen word, word wendividend uit daardie rondte in die volgende rondte gewed totdat alle rondtes wen.',
'bonus_launch': 'Wen x2 op alle weddenskappe in die "Bonuslansering"-rondte!',
'multiplier_history': 'Vermenigvuldigergeskiedenis',
'will_start_soon': 'sal binnekort begin',
'hurry_up_and_place_your_bets': 'Maak gou en gaan jou weddenskappe aan!',
'active_bets': 'Aktiewe weddenskappe',
'shop': 'winkel',
'loading': 'Laai',
'lost_connection': 'Verbinding verloor',
'jackpot_winners': 'Jackpot Winners',
'round': 'Rondte',
'round_id': 'Rondte-ID',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.so = {
'bet_bets_tab': 'CHELETE E BECHANG',
'coeff': 'KOEFE.',
'win': 'HAPA',
'game_explanation_1': 'Khetha semenahanyi sa hau, ‘me haeba semenahanyi sa papali se feta sa khetho ea hau, chelete eo u e hapileng e tla',
'game_explanation_2': 'Semenahanyi sa hao x ho betjha ha hao',
'retail_max_mp_1': 'Hapa ho fihlela ho',
'retail_max_mp_2': 'chelete ea ho becha',
'play_multibets': 'Ciyaar',
'multibets': 'MULTIBETS',
'bonus_game': 'CIYAAR BONUS',
'all_wins_doubled': 'Guulaha oo dhan waa la labanlaabay!',
'multibets_paragraph_1': 'si aad u kordhiso nasiibkaaga!',
'multibets_paragraph_2': 'Waxaad isku dari kartaa sharad ilaa toban wareeg ah oo aad ku guulaysato lacag bixinno badan!',
'multibets_paragraph_3': 'Mar kasta oo wareeg la guuleysto, faa\'iidada ka soo baxda wareeggaas ayaa lagu shubaa wareegga xiga, ilaa dhammaan wareegyada ay guuleystaan.',
'bonus_launch': 'Chelete e bechileng e imenahanya ha beli (x2) ho mokhahelo oa “Qala Bonase”!',
'multiplier_history': 'Nalane ea Semenahanyi',
'will_start_soon': 'e tla qala haufinyane',
'hurry_up_and_place_your_bets': 'Itlole matjato ‘me u beche!',
'active_bets': 'Lipapali tse Bechoang',
'shop': 'shopo',
'loading': 'Ea loada',
'lost_connection': 'Khokahanyo e Khaohile',
'jackpot_winners': 'Jackpot Winners',
'round': 'Mokhahlelo oa Papali',
'round_id': 'Boitsebiso ba Sebapali',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.tn = {
'bet_bets_tab': 'MOPUTSO',
'coeff': 'COEFF',
'win': 'PHENYO',
'game_explanation_1': 'Tlhopha seatisi sa gago, mme fa seatisi sa motshameko se feta seatisi se o se tlhophileng, phenyo ya gago e tla',
'game_explanation_2': 'Seatisi sa gago x Go Betšha ga gago',
'retail_max_mp_1': 'Fenya go fitlha go',
'retail_max_mp_2': 'go betšha ga gago!',
'play_multibets': 'Play',
'multibets': 'MULTIBETS',
'bonus_game': 'MOtshameko wa BONASE',
'all_wins_doubled': 'Diphenyo tsotlhe di menagane gabedi!',
'multibets_paragraph_1': 'go oketsa ditšhono tsa gago!',
'multibets_paragraph_2': 'O ka nna wa kopanya dibetšhe mo ditikologong tse di ka fitlhang go di le lesome go nna multibet e le nngwe mme wa fenya dituelo tse dintsi!',
'multibets_paragraph_3': 'Nako le nako fa go fenngwa tikologo, karolo e e fentsweng go tswa mo tikologong eo e betšha mo tikologong e e latelang, go fitlha ditikologo tsotlhe di fenya.',
'bonus_launch': 'Fenya x2 mo go betšheng gotlhe ga gago mo "tikologong ya Bonus Launch"!',
'multiplier_history': 'Hisitori ya Seatisi',
'will_start_soon': 'e tla simolola go sa le gale',
'hurry_up_and_place_your_bets': 'Itlhaganele mme o betšhe!',
'active_bets': 'Go Betšha go go Tsweletseng',
'shop': 'lebenkele',
'loading': 'E a laisa',
'lost_connection': 'Kgokaganyo e Latlhegile',
'jackpot_winners': 'Jackpot Winners',
'round': 'Tikologo',
'round_id': 'Id ya Tikologo',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.ar = {
'bet_bets_tab': 'رهان',
'coeff': 'المعامل',
'win': 'فوز',
'game_explanation_1': 'اختر المضاعف الخاص بك، وإذا تجاوز مضاعف اللعبة المضاعف الذي اخترته، فسيكون فوزك هو',
'game_explanation_2': 'المضاعف × رهانك',
'retail_max_mp_1': 'الفوز بما يصل الى',
'retail_max_mp_2': 'رهان!',
'play_multibets': 'يلعب',
'multibets': 'الرهانات المتعددة',
'bonus_game': 'يتم مضاعفة جميع المكاسب!',
'all_wins_doubled': 'لزيادة احتمالاتك!',
'multibets_paragraph_1': 'يمكنك الجمع بين الرهانات على ما يصل إلى عشر جولات في رهان متعدد واحد والفوز بمبالغ ضخمة!',
'multibets_paragraph_2': 'في كل مرة يتم فيها الفوز بجولة، يتم المراهنة على الأرباح الفائزة من تلك الجولة في الجولة التالية، حتى تصبح جميع الجولات فائزة.',
'multibets_paragraph_3': 'كل مرة',
'bonus_launch': 'اربح ×2 على جميع الرهانات في جولة "Bonus Launch"!',
'multiplier_history': 'التاريخ المضاعف',
'will_start_soon': 'سوف تبدأ قريبا',
'hurry_up_and_place_your_bets': 'عجلوا ووضع الرهانات الخاصة بك!',
'active_bets': 'الرهانات النشطة',
'shop': 'محل',
'loading': 'جاري التحميل',
'lost_connection': 'فقدان الاتصال بالإنترنت',
'jackpot_winners': 'Jackpot Winners',
'round': 'الجولة',
'round_id': 'معرف الجولة',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.bg = {
'bet_bets_tab': 'СУМА НА ЗАЛОГА',
'coeff': 'КОЕФ.',
'win': 'ПЕЧАЛБА',
'game_explanation_1': 'Изберете вашия множител и ако множителят на играта надвиши избрания от вас множител, вашата печалба ще бъде',
'game_explanation_2': 'Вашият множител x Вашият залог',
'retail_max_mp_1': 'Спечелете до',
'retail_max_mp_2': 'вашият залог!',
'play_multibets': 'Играйте',
'multibets': 'МУЛТИБЕТ',
'bonus_game': 'БОНУС ИГРА',
'all_wins_doubled': 'Всички печалби се удвояват!',
'multibets_paragraph_1': 'за да увеличите шансовете си!',
'multibets_paragraph_2': 'Можете да комбинирате залози на до десет рунда в един мулти залог и да спечелите масивни печалби!',
'multibets_paragraph_3': 'Всеки път, когато бъде спечелен рунд, печалбата от този рунд се залага на следващия рунд, докато всички рундове са печеливши.',
'bonus_launch': 'Спечелете x2 на всички залози в рунда „Bonus Launch“!',
'multiplier_history': 'История на множителя',
'will_start_soon': 'ще започне скоро',
'hurry_up_and_place_your_bets': 'Побързайте и залагайте!',
'active_bets': 'Активни залози',
'shop': 'магазин',
'loading': 'Зареждане',
'lost_connection': 'Прекъсната връзка',
'jackpot_winners': 'Jackpot Winners',
'round': 'Кръг',
'round_id': 'Id на Кръга',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.de = {
'bet_bets_tab': 'EINSATZ',
'coeff': 'FAKTOR',
'win': 'GEWINN',
'game_explanation_1': 'Wählen Sie Ihren Multiplikator. Wenn der Spielmultiplikator den von Ihnen gewählten Multiplikator übersteigt, erhalten Sie einen Gewinn',
'game_explanation_2': 'Ihr Multiplikator x Ihr Einsatz',
'retail_max_mp_1': 'Gewinne bis zu',
'retail_max_mp_2': 'deine Wette!',
'play_multibets': 'Spielen',
'multibets': 'MULTIWETTEN',
'bonus_game': 'BONUSSPIEL',
'all_wins_doubled': 'Alle Gewinne werden verdoppelt!',
'multibets_paragraph_1': 'um Ihre Chancen zu erhöhen!',
'multibets_paragraph_2': 'Sie können Wetten auf bis zu zehn Runden zu einer einzigen Kombiwette kombinieren und riesige Auszahlungen gewinnen!',
'multibets_paragraph_3': 'Jedes Mal, wenn eine Runde gewonnen wird, wird die Gewinndividende dieser Runde auf die nächste Runde gesetzt, bis alle Runden gewinnen.',
'bonus_launch': 'Gewinnen Sie x2 bei allen Wetten in der „Bonus Launch“-Runde!',
'multiplier_history': 'Multiplikatorgeschichte',
'will_start_soon': 'wird bald beginnen',
'hurry_up_and_place_your_bets': 'Beeilen Sie sich und platzieren Sie Ihre Wetten!',
'active_bets': 'Aktive Wetten',
'shop': 'geschäft',
'loading': 'Lädt',
'lost_connection': 'Verbindung unterbrochen',
'jackpot_winners': 'Jackpot Winners',
'round': 'Runde',
'round_id': 'Runden-ID',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.el = {
'bet_bets_tab': 'ΠΟΝΤΑΡΙΣΜΑ',
'coeff': 'ΣΥΝΤΕΛ.',
'win': 'ΚΕΡΔΟΣ',
'game_explanation_1': 'Επιλέξτε τον πολλαπλασιαστή σας και εάν ο πολλαπλασιαστής του παιχνιδιού ξεπεράσει τον πολλαπλασιαστή που έχετε επιλέξει, η νίκη σας θα είναι',
'game_explanation_2': 'Ο πολλαπλασιαστής σας x Το στοίχημά σας',
'retail_max_mp_1': 'Κερδίστε μέχρι',
'retail_max_mp_2': 'το στοίχημά σου!',
'play_multibets': 'Παίζω',
'multibets': 'MULTIBETS',
'bonus_game': 'ΠΑΙΧΝΙΔΙ ΜΠΟΝΟΥΣ',
'all_wins_doubled': 'Όλες οι νίκες διπλασιάστηκαν!',
'multibets_paragraph_1': 'για να αυξήσεις τις πιθανότητες σου!',
'multibets_paragraph_2': 'Μπορείτε να συνδυάσετε στοιχήματα σε έως και δέκα γύρους σε ένα μόνο multibet και να κερδίσετε τεράστιες πληρωμές!',
'multibets_paragraph_3': 'Κάθε φορά που κερδίζεται ένας γύρος, το κερδισμένο μέρισμα από αυτόν τον γύρο στοιχηματίζεται στον επόμενο γύρο, μέχρι να κερδηθούν όλοι οι γύροι.',
'bonus_launch': 'Κερδίστε x2 σε όλα τα στοιχήματα στον γύρο «Bonus Launch»!',
'multiplier_history': 'Ιστορία πολλαπλασιαστή',
'will_start_soon': 'θα ξεκινήσει σύντομα',
'hurry_up_and_place_your_bets': 'Βιαστείτε και τοποθετήστε τα στοιχήματά σας!',
'active_bets': 'Ενεργά στοιχήματα',
'shop': 'καταστημα',
'loading': 'Φόρτωση',
'lost_connection': 'Απώλεια Σύνδεσης',
'jackpot_winners': 'Jackpot Winners',
'round': 'Γύρος',
'round_id': 'Αναγνωριστικό Γύρου',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.es = {
'bet_bets_tab': 'APUESTA',
'coeff': 'COEF.',
'win': 'PREMIO',
'game_explanation_1': 'Elija su multiplicador y, si el multiplicador del juego excede el multiplicador elegido, su ganancia será',
'game_explanation_2': 'Tu multiplicador x tu apuesta',
'retail_max_mp_1': 'Ganar hasta',
'retail_max_mp_2': '¡tu apuesta!',
'play_multibets': 'Jugar',
'multibets': 'MULTIAPUESTAS',
'bonus_game': 'JUEGO DE BONIFICACIÓN',
'all_wins_doubled': '¡Todas las ganancias se duplican!',
'multibets_paragraph_1': 'para aumentar tus probabilidades!',
'multibets_paragraph_2': '¡Puedes combinar apuestas de hasta diez rondas en una sola apuesta múltiple y ganar pagos masivos!',
'multibets_paragraph_3': 'Cada vez que se gana una ronda, el dividendo ganador de esa ronda se apuesta en la siguiente ronda, hasta que todas las rondas sean ganadoras.',
'bonus_launch': '¡Gana x2 en todas las apuestas en la ronda "Bonus Launch"!',
'multiplier_history': 'Historia del multiplicador',
'will_start_soon': 'empezara pronto',
'hurry_up_and_place_your_bets': '¡Date prisa y haz tus apuestas!',
'active_bets': 'Apuestas activas',
'shop': 'comercio',
'loading': 'Cargando',
'lost_connection': 'Conexión Perdida',
'jackpot_winners': 'Jackpot Winners',
'round': 'Ronda',
'round_id': 'ID de la partida',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.fr = {
'bet_bets_tab': 'MISE',
'coeff': 'COEFF.',
'win': 'GAIN',
'game_explanation_1': 'Choisissez votre multiplicateur, et si le multiplicateur de jeu dépasse le multiplicateur choisi, votre gain sera',
'game_explanation_2': 'Votre multiplicateur x votre mise',
'retail_max_mp_1': 'Gagnez jusqu\'à',
'retail_max_mp_2': 'votre pari !',
'play_multibets': 'Jouer',
'multibets': 'PARIS MULTIPLES',
'bonus_game': 'JEU BONUS',
'all_wins_doubled': 'Tous les gains sont doublés !',
'multibets_paragraph_1': 'pour augmenter vos chances !',
'multibets_paragraph_2': 'Vous pouvez combiner des paris sur jusqu\'à dix tours en un seul multipari et gagner des gains massifs !',
'multibets_paragraph_3': 'Chaque fois qu\'un tour est gagné, les dividendes gagnants de ce tour sont misés sur le tour suivant, jusqu\'à ce que tous les tours soient gagnants.',
'bonus_launch': 'Gagnez x2 sur tous les paris lors du tour « Bonus Launch » !',
'multiplier_history': 'Historique du multiplicateur',
'will_start_soon': 'va bientôt commencer',
'hurry_up_and_place_your_bets': 'Dépêchez-vous et placez vos paris !',
'active_bets': 'Paris actifs',
'shop': 'boutique',
'loading': 'Chargement',
'lost_connection': 'Connexion perdue',
'jackpot_winners': 'Jackpot Winners',
'round': 'Partie',
'round_id': 'ID de la partie ',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.he = {
'bet_bets_tab': 'סכום הימור',
'coeff': 'מקדם',
'win': 'זכייה',
'game_explanation_1': 'בחר את המכפיל שלך, ואם מכפיל המשחק עולה על המכפיל שבחרת, הניצחון שלך יהיה',
'game_explanation_2': 'המכפיל שלך x ההימור שלך',
'retail_max_mp_1': 'נצח עד',
'retail_max_mp_2': 'ההימור שלך!',
'play_multibets': 'לְשַׂחֵק',
'multibets': 'MULTIBETS',
'bonus_game': 'משחק בונוס',
'all_wins_doubled': 'כל הניצחונות הוכפלו!',
'multibets_paragraph_1': 'to increase your odds!',
'multibets_paragraph_2': 'You may combine bets on up to ten rounds into a single multibet and win massive payouts!',
'multibets_paragraph_3': 'Each time a round is won, winning dividend from that round is wagered on the next round, until all rounds are winning.',
'bonus_launch': 'זכה x2 בכל ההימורים בסבב "השקת בונוס"!',
'multiplier_history': 'היסטוריית מכפיל',
'will_start_soon': 'יתחיל בקרוב',
'hurry_up_and_place_your_bets': 'מהרו והצבו את ההימורים שלכם!',
'active_bets': 'הימורים פעילים',
'shop': 'לִקְנוֹת',
'loading': 'טוען',
'lost_connection': 'אבד הקשר',
'jackpot_winners': 'Jackpot Winners',
'round': 'סיבוב ',
'round_id':  'זיהוי סיבוב‎',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.hr = {
'bet_bets_tab': 'ULOG',
'coeff': 'KOEF.',
'win': 'DOBITAK',
'game_explanation_1': 'Odaberite množitelj, a ako množitelj igre premaši odabrani množitelj, dobit ćete',
'game_explanation_2': 'Vaš množitelj x Vaša oklada',
'retail_max_mp_1': 'Osvojiti do',
'retail_max_mp_2': 'Vaša oklada!',
'play_multibets': 'Igraj',
'multibets': 'MULTIBETS',
'bonus_game': 'BONUS IGRA',
'all_wins_doubled': 'Svi dobici udvostručeni!',
'multibets_paragraph_1': 'da povećate svoje izglede!',
'multibets_paragraph_2': 'Možete kombinirati oklade na do deset rundi u jednu multibet i osvojiti masivne isplate!',
'multibets_paragraph_3': 'Svaki put kada se runda dobije, dobivena dividenda iz te runde ulaže se u sljedeću rundu, sve dok sve runde ne budu dobitne.',
'bonus_launch': 'Dobijte x2 na svim okladama u rundi „Pokretanje bonusa“!',
'multiplier_history': 'Povijest množitelja',
'will_start_soon': 'uskoro će početi',
'hurry_up_and_place_your_bets': 'Požurite i stavite svoje oklade!',
'active_bets': 'Aktivne oklade',
'shop': 'dućan',
'loading': 'Učitavanje',
'lost_connection': 'Prekinuta veza',
'jackpot_winners': 'Jackpot Winners',
'round': 'Runda',
'round_id': 'ID runde',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.hu = {
'bet_bets_tab': 'TÉT',
'coeff': 'EGYÜTTH.',
'win': 'NYEREMÉNY',
'game_explanation_1': 'Válaszd ki a szorzót, és ha a játék szorzója meghaladja a választott szorzót, a nyereményed lesz',
'game_explanation_2': 'Az Ön szorzója x a tétje',
'retail_max_mp_1': 'Nyerj akár',
'retail_max_mp_2': 'a fogadásod!',
'play_multibets': 'Játék',
'multibets': 'MULTIBETS',
'bonus_game': 'BÓNUSZ JÁTÉK',
'all_wins_doubled': 'Minden győzelem megduplázódott!',
'multibets_paragraph_1': 'hogy növelje az esélyeit!',
'multibets_paragraph_2': 'A fogadásokat akár tíz körben is kombinálhatja egyetlen multitétté, és hatalmas nyereményeket nyerhet!',
'multibets_paragraph_3': 'Minden alkalommal, amikor egy kört megnyernek, az adott körből nyert osztalékot a következő körben fogadják meg, amíg minden kör nyer.',
'bonus_launch': 'Nyerj 2-szer minden fogadást a „Bónuszindítás” körben!',
'multiplier_history': 'Szorzótörténet',
'will_start_soon': 'hamarosan indul',
'hurry_up_and_place_your_bets': 'Siess és tedd meg a fogadásod!',
'active_bets': 'Aktív fogadások',
'shop': 'üzlet',
'loading': 'Betöltés',
'lost_connection': 'Megszakadt a kapcsolat',
'jackpot_winners': 'Jackpot Winners',
'round': 'Kör',
'round_id': 'Kör azonosítója',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.it = {
'bet_bets_tab': 'PUNTATA',
'coeff': 'COEFF.',
'win': 'VINCITA',
'game_explanation_1': 'Scegli il tuo moltiplicatore e, se il moltiplicatore del gioco supera il moltiplicatore scelto, la tua vincita sarà',
'game_explanation_2': 'Il tuo moltiplicatore x la tua scommessa',
'retail_max_mp_1': 'Vinci fino a',
'retail_max_mp_2': 'la tua scommessa!',
'play_multibets': 'Giocare',
'multibets': 'SCOMMESSE MULTIPLE',
'bonus_game': 'GIOCO BONUS',
'all_wins_doubled': 'Tutte le vincite raddoppiate!',
'multibets_paragraph_1': 'per aumentare le tue probabilità!',
'multibets_paragraph_2': 'Puoi combinare le scommesse su un massimo di dieci round in un\'unica scommessa multipla e vincere enormi vincite!',
'multibets_paragraph_3': 'Ogni volta che viene vinto un round, il dividendo vincente di quel round viene scommesso sul round successivo, finché tutti i round non risultano vincenti.',
'bonus_launch': 'Vinci x2 su tutte le scommesse nel round "Lancio bonus"!',
'multiplier_history': 'Storia del moltiplicatore',
'will_start_soon': 'inizierà presto',
'hurry_up_and_place_your_bets': 'Affrettati e piazza le tue scommesse!',
'active_bets': 'Scommesse attive',
'shop': 'negozio',
'loading': 'Caricamento',
'lost_connection': 'Connessione interrotta',
'jackpot_winners': 'Jackpot Winners',
'round': 'Round',
'round_id': 'Id round',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.mk = {
'bet_bets_tab': 'ВЛОГ',
'coeff': 'КОЕФ.',
'win': 'ПОБЕДА',
'game_explanation_1': 'Изберете го вашиот множител и ако множителот на играта го надмине избраниот множител, вашата победа ќе биде',
'game_explanation_2': 'Вашиот мултипликатор x вашиот облог',
'retail_max_mp_1': 'Победи до',
'retail_max_mp_2': 'вашиот облог!',
'play_multibets': 'Играј',
'multibets': 'МУЛТИБЕТИ',
'bonus_game': 'БОНУС ИГРА',
'all_wins_doubled': 'Сите победи се удвоени!',
'multibets_paragraph_1': 'да ги зголемите вашите шанси!',
'multibets_paragraph_2': 'Може да комбинирате облози до десет рунди во еден мултиоблог и да добивате огромни исплати!',
'multibets_paragraph_3': 'Секој пат кога се добива едно коло, добитната дивиденда од таа рунда се обложува во следното коло, додека не се добијат сите рунди.',
'bonus_launch': 'Победи x2 на сите облози во рундата „Bonus Launch“!',
'multiplier_history': 'Историја на мултипликатор',
'will_start_soon': 'ќе започне наскоро',
'hurry_up_and_place_your_bets': 'Побрзајте и ставете ги вашите облози!',
'active_bets': 'Активни облози',
'shop': 'продавница',
'loading': 'Се вчитува',
'lost_connection': 'Изгубена врска',
'jackpot_winners': 'Jackpot Winners',
'round': 'Рунда',
'round_id': 'ID на рундата',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.pl = {
'bet_bets_tab': 'STAWKA',
'coeff': 'PARAMETR',
'win': 'WYGRANA',
'game_explanation_1': 'Wybierz mnożnik, a jeśli mnożnik gry przekroczy wybrany mnożnik, Twoja wygrana zostanie przyznana',
'game_explanation_2': 'Twój mnożnik x Twój zakład',
'retail_max_mp_1': 'Wygraj do',
'retail_max_mp_2': 'twój zakład!',
'play_multibets': 'Grać',
'multibets': 'MULTIBETY',
'bonus_game': 'GRA BONUSOWA',
'all_wins_doubled': 'Wszystkie wygrane podwojone!',
'multibets_paragraph_1': 'aby zwiększyć swoje szanse!',
'multibets_paragraph_2': 'Możesz łączyć zakłady z maksymalnie dziesięciu rund w jeden zakład wielokrotny i wygrywać ogromne wygrane!',
'multibets_paragraph_3': 'Za każdym razem, gdy wygrywana jest runda, wygrana z tej rundy jest obstawiana w następnej rundzie, aż do momentu, gdy wszystkie rundy zakończą się wygraną.',
'bonus_launch': 'Wygraj x2 na wszystkie zakłady w rundzie „Bonusowe uruchomienie”!',
'multiplier_history': 'Historia mnożnika',
'will_start_soon': 'zacznie się wkrótce',
'hurry_up_and_place_your_bets': 'Pospiesz się i obstawiaj zakłady!',
'active_bets': 'Aktywne zakłady',
'shop': 'sklep',
'loading': 'Ładowanie',
'lost_connection': 'Utracone połączenie',
'jackpot_winners': 'Jackpot Winners',
'round': 'Runda',
'round_id': 'ID rundy',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.pt = {
'bet_bets_tab': 'VALOR DA APOSTA',
'coeff': 'COEF.',
'win': 'GANHO',
'game_explanation_1': 'Escolha o seu multiplicador e, se o multiplicador do jogo exceder o multiplicador escolhido, sua vitória será',
'game_explanation_2': 'Seu Multiplicador x Sua Aposta',
'retail_max_mp_1': 'Ganhe até',
'retail_max_mp_2': 'sua aposta!',
'play_multibets': 'Jogar',
'multibets': 'MULTIAPOSTAS',
'bonus_game': 'JOGO DE BÓNUS',
'all_wins_doubled': 'Todas as vitórias duplicaram!',
'multibets_paragraph_1': 'para aumentar suas chances!',
'multibets_paragraph_2': 'Você pode combinar apostas em até dez rodadas em uma única aposta múltipla e ganhar pagamentos enormes!',
'multibets_paragraph_3': 'Cada vez que uma rodada é ganha, o dividendo vencedor dessa rodada é apostado na próxima rodada, até que todas as rodadas sejam vencedoras.',
'bonus_launch': 'Ganhe x2 em todas as apostas na rodada “Bonus Launch”!',
'multiplier_history': 'Histórico do Multiplicador',
'will_start_soon': 'começará em breve',
'hurry_up_and_place_your_bets': 'Apresse-se e faça suas apostas!',
'active_bets': 'Apostas Ativas',
'shop': 'comprar',
'loading': 'A carregar',
'lost_connection': 'A Conexão Perdeu-se',
'jackpot_winners': 'Jackpot Winners',
'round': 'Ronda',
'round_id': 'Id da Ronda',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.br = {
'bet_bets_tab': 'VALOR DA APOSTA',
'coeff': 'COEF.',
'win': 'GANHO',
'game_explanation_1': 'Escolha o seu multiplicador e, se o multiplicador do jogo exceder o multiplicador escolhido, sua vitória será',
'game_explanation_2': 'Seu Multiplicador x Sua Aposta',
'retail_max_mp_1': 'Ganhe até',
'retail_max_mp_2': 'sua aposta!',
'play_multibets': 'Jogar',
'multibets': 'MULTIAPOSTAS',
'bonus_game': 'JOGO BÔNUS',
'all_wins_doubled': 'Todos os ganhos são dobrados!',
'multibets_paragraph_1': 'para aumentar suas chances!',
'multibets_paragraph_2': 'Você pode combinar apostas em até dez rodadas em uma única aposta múltipla e ganhar pagamentos enormes!',
'multibets_paragraph_3': 'Cada vez que uma rodada é ganha, o dividendo vencedor dessa rodada é apostado na próxima rodada, até que todas as rodadas sejam vencedoras.',
'bonus_launch': 'Ganhe x2 em todas as apostas na rodada “Bonus Launch”!',
'multiplier_history': 'Histórico do Multiplicador',
'will_start_soon': 'começará em breve',
'hurry_up_and_place_your_bets': 'Apresse-se e faça suas apostas!',
'active_bets': 'Apostas Ativas',
'shop': 'comprar',
'loading': 'A carregar',
'lost_connection': 'A Conexão Perdeu-se',
'jackpot_winners': 'Jackpot Winners',
'round': 'Ronda',
'round_id': 'Id da Ronda',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.ro = {
'bet_bets_tab': 'MIZĂ',
'coeff': 'COEF.',
'win': 'CÂȘTIG',
'game_explanation_1': 'Alege-ți multiplicatorul, iar dacă multiplicatorul jocului depășește multiplicatorul ales, câștigul tău va fi',
'game_explanation_2': 'Multiplicatorul tău x pariul tău',
'retail_max_mp_1': 'Câștigă până la',
'retail_max_mp_2': 'pariul tau!',
'play_multibets': 'Joaca',
'multibets': 'MULTIBETURI',
'bonus_game': 'JOC BONUS',
'all_wins_doubled': 'Toate victoriile s-au dublat!',
'multibets_paragraph_1': 'pentru a-ți crește șansele!',
'multibets_paragraph_2': 'Puteți combina pariuri pe până la zece runde într-un singur multibet și puteți câștiga plăți masive!',
'multibets_paragraph_3': 'De fiecare dată când este câștigată o rundă, dividendul câștigător din acea rundă este pariat în runda următoare, până când toate rundele sunt câștigătoare.',
'bonus_launch': 'Câștigă x2 la toate pariurile din runda „Lansare bonus”!',
'multiplier_history': 'Istoricul multiplicatorului',
'will_start_soon': 'va începe în curând',
'hurry_up_and_place_your_bets': 'Grăbește-te și pariază!',
'active_bets': 'Pariuri active',
'shop': 'magazin',
'loading': 'Se încarcă',
'lost_connection': 'Conexiune Pierdută',
'jackpot_winners': 'Jackpot Winners',
'round': 'Rundă',
'round_id': 'ID rundă',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.ru = {
'bet_bets_tab': 'СТАВКА',
'coeff': 'КОЭФФ.',
'win': 'ВЫИГРЫШ',
'game_explanation_1': 'Выберите свой множитель, и если игровой множитель превысит выбранный вами множитель, ваш выигрыш составит',
'game_explanation_2': 'Ваш множитель x ваша ставка',
'retail_max_mp_1': 'Выиграйте до',
'retail_max_mp_2': 'ваша ставка!',
'play_multibets': 'Играть',
'multibets': 'МУЛЬТИСТАВКИ',
'bonus_game': 'БОНУСНАЯ ИГРА',
'all_wins_doubled': 'Все выигрыши удваиваются!',
'multibets_paragraph_1': 'чтобы увеличить свои шансы!',
'multibets_paragraph_2': 'Вы можете объединить ставки на десять раундов в одну экспресс-ставку и выиграть огромные выплаты!',
'multibets_paragraph_3': 'Каждый раз, когда раунд выигран, дивиденды от выигрыша в этом раунде делаются на следующий раунд, пока все раунды не будут выигрышными.',
'bonus_launch': 'Выиграйте х2 по всем ставкам в раунде «Запуск бонусов»!',
'multiplier_history': 'История множителя',
'will_start_soon': 'скоро начнется',
'hurry_up_and_place_your_bets': 'Спешите делать ставки!',
'active_bets': 'Активные ставки',
'shop': 'magazin',
'loading': 'Загрузка',
'lost_connection': 'Соединение прервано',
'jackpot_winners': 'Jackpot Winners',
'round': 'Раунд',
'round_id': '‎№ раунда',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.sq = {
'bet_bets_tab': 'SHUMA E BASTIT',
'coeff': 'KOEF.',
'win': 'FITIMI',
'game_explanation_1': 'Zgjidhni shumëzuesin tuaj dhe nëse shumëzuesi i lojës tejkalon shumëzuesin tuaj të zgjedhur, fitorja juaj do të jetë',
'game_explanation_2': 'Shumëzuesi juaj x Basti juaj',
'retail_max_mp_1': 'Fito deri në',
'retail_max_mp_2': 'basti juaj!',
'play_multibets': 'Luaj',
'multibets': 'MULTIBETE',
'bonus_game': 'LOJË BONUS',
'all_wins_doubled': 'Të gjitha fitoret u dyfishuan!',
'multibets_paragraph_1': 'për të rritur shanset tuaja!',
'multibets_paragraph_2': 'Ju mund të kombinoni baste deri në dhjetë raunde në një multibet të vetëm dhe të fitoni pagesa masive!',
'multibets_paragraph_3': 'Sa herë që fitohet një raund, dividenti fitues nga ai raund vihet në raundin tjetër, derisa të fitojnë të gjitha raundet.',
'bonus_launch': 'Fito x2 në të gjitha bastet në raundin "Launch Bonus"!',
'multiplier_history': 'Historia e shumëzuesit',
'will_start_soon': 'do të fillojë së shpejti',
'hurry_up_and_place_your_bets': 'Nxitoni dhe vendosni bastet tuaja!',
'active_bets': 'Baste aktive',
'shop': 'dyqani',
'loading': 'Po ngarkohet',
'lost_connection': 'Lidhja u shkëput',
'jackpot_winners': 'Jackpot Winners',
'round': 'Raundi',
'round_id': 'Id-ja e raundit',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.sv = {
'bet_bets_tab': 'INSATS',
'coeff': 'KOEFF.',
'win': 'VINST',
'game_explanation_1': 'Valitse kertoimesi, ja jos pelin kerroin ylittää valitsemasi kertoimen, voittosi on',
'game_explanation_2': 'Kerroin x panoksesi',
'retail_max_mp_1': 'Voita jopa',
'retail_max_mp_2': 'vetosi!',
'play_multibets': 'Spela',
'multibets': 'MULTIBETS',
'bonus_game': 'BONUSSPEL',
'all_wins_doubled': 'Alla vinster fördubblades!',
'multibets_paragraph_1': 'för att öka dina odds!',
'multibets_paragraph_2': 'Du kan kombinera satsningar på upp till tio omgångar till en enda multibet och vinna enorma utbetalningar!',
'multibets_paragraph_3': 'Varje gång en omgång vinner, satsas vinnande utdelning från den omgången i nästa omgång, tills alla omgångar vinner.',
'bonus_launch': 'Voita x2 kaikista "Bonus Launch" -kierroksen panoksista!',
'multiplier_history': 'Kerroinhistoria',
'will_start_soon': 'alkaa pian',
'hurry_up_and_place_your_bets': 'Pidä kiirettä ja aseta vetosi!',
'active_bets': 'Aktiiviset vedot',
'shop': 'myymälä',
'loading': 'Laddar',
'lost_connection': 'Förlorad anslutning',
'jackpot_winners': 'Jackpot Winners',
'round': 'Omgång',
'round_id': 'Spelomgångens id',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.tr = {
'bet_bets_tab': 'BAHİS TUTARI',
'coeff': 'KATSAYI',
'win': 'KAZANÇ',
'game_explanation_1': 'Çarpanınızı seçin; oyun çarpanı seçtiğiniz çarpanı aşarsa kazancınız',
'game_explanation_2': 'Çarpanınız x Bahsiniz',
'retail_max_mp_1': 'Kazanmak',
'retail_max_mp_2': 'senin bahsin!',
'play_multibets': 'Oynamak',
'multibets': 'MULTİBAHİSLER',
'bonus_game': 'BONUS OYUN',
'all_wins_doubled': 'Tüm kazançlar ikiye katlandı!',
'multibets_paragraph_1': 'şansınızı artırmak için!',
'multibets_paragraph_2': 'On tura kadar olan bahisleri tek bir çoklu bahiste birleştirebilir ve büyük kazançlar kazanabilirsiniz!',
'multibets_paragraph_3': 'Her tur kazanıldığında, o turdan elde edilen kazanç, tüm turlar kazanana kadar bir sonraki turda bahis olarak oynanır.',
'bonus_launch': 'Bonus Lansmanı" turundaki tüm bahislerde x2 kazanın!',
'multiplier_history': 'Çarpan Geçmişi',
'will_start_soon': 'yakında başlayacak',
'hurry_up_and_place_your_bets': 'Acele edin ve bahislerinizi yapın!',
'active_bets': 'Aktif Bahisler',
'shop': 'mağaza',
'loading': 'Yükleniyor',
'lost_connection': 'Bağlantı kaybedildi',
'jackpot_winners': 'Jackpot Winners',
'round': 'Raunt',
'round_id': 'Raunt ID',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.uk = {
'bet_bets_tab': 'СТАВКА',
'coeff': 'КОЕФ.',
'win': 'ВИГРАШ',
'game_explanation_1': 'Виберіть множник, і якщо множник гри перевищить вибраний множник, ваш виграш буде',
'game_explanation_2': 'Ваш множник x Ваша ставка',
'retail_max_mp_1': 'Виграти до',
'retail_max_mp_2': 'ваша ставка!',
'play_multibets': 'Toneelstuk',
'multibets': 'MULTIBETS',
'bonus_game': 'BONUS GAME',
'all_wins_doubled': 'All wins doubled!',
'multibets_paragraph_1': 'om uw kansen te vergroten!',
'multibets_paragraph_2': 'Je kunt weddenschappen op maximaal tien ronden combineren tot één enkele multibet en enorme uitbetalingen winnen!',
'multibets_paragraph_3': 'Elke keer dat een ronde wordt gewonnen, wordt het winnende dividend uit die ronde ingezet in de volgende ronde, totdat alle rondes winnend zijn.',
'bonus_launch': 'Виграйте 2 рази на всіх ставках у раунді «Bonus Launch»!',
'multiplier_history': 'Історія множників',
'will_start_soon': 'незабаром розпочнеться',
'hurry_up_and_place_your_bets': 'Поспішайте робити ставки!',
'active_bets': 'Активні ставки',
'shop': 'магазин',
'loading': 'Завантаження',
'lost_connection': 'Сталося роз’єднання',
'jackpot_winners': 'Jackpot Winners',
'round': 'Раунд',
'round_id': 'Id раунду',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.sr = {
'bet_bets_tab': 'ULOG',
'coeff': 'KOEF.',
'win': 'DOBITAK',
'game_explanation_1': 'Izaberite svoj množilac, i ako množilac igre premaši Vaš izabrani, Vaš dobitak će biti',
'game_explanation_2': 'Vaš množilac X Vaša opklada',
'retail_max_mp_1': 'Osvojite do',
'retail_max_mp_2': 'tvoje opklade!',
'play_multibets': 'Igraj',
'multibets': 'MULTIBETS',
'bonus_game': 'BONUS IGRA',
'all_wins_doubled': 'Svi dobici su udvostručeni!',
'multibets_paragraph_1': 'da povećate svoje šanse!',
'multibets_paragraph_2': 'Možete kombinovati opklade na do deset rundi u jednu multibet i osvojiti ogromne isplate!',
'multibets_paragraph_3': 'Svaki put kada se dobije runda, dobitna dividenda iz te runde se kladi u sledećoj rundi, sve dok sve runde ne budu pobedničke.',
'bonus_launch': 'Osvojite X2 na svim opkladama u rundi „Bonus uzletanje“!',
'multiplier_history': 'Istorija multiplikatora',
'will_start_soon': 'uskoro će početi',
'hurry_up_and_place_your_bets': 'Požurite i uložite svoje opklade!',
'active_bets': 'Aktivne opklade',
'shop': 'poslovnica',
'loading': 'Učitavanje',
'lost_connection': 'Lost Connection',
'jackpot_winners': 'Jackpot Winners',
'round': 'Igra',
'round_id': 'Broj runde',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.rs = {
'bet_bets_tab': 'ULOG',
'coeff': 'KOEF.',
'win': 'DOBITAK',
'game_explanation_1': 'Izaberite svoj množilac, i ako množilac igre premaši Vaš izabrani, Vaš dobitak će biti',
'game_explanation_2': 'Vaš množilac X Vaša opklada',
'retail_max_mp_1': 'Osvojite do',
'retail_max_mp_2': 'tvoje opklade!',
'play_multibets': 'Igraj',
'multibets': 'MULTIBET',
'bonus_game': 'BONUS IGRA',
'all_wins_doubled': 'Svi dobici su udvostručeni!',
'multibets_paragraph_1': 'da povećate svoje šanse!',
'multibets_paragraph_2': 'Možete kombinovati opklade na do deset rundi u jednu multibet i osvojiti ogromne isplate!',
'multibets_paragraph_3': 'Svaki put kada se dobije runda, dobitna dividenda iz te runde se kladi u sledećoj rundi, sve dok sve runde ne budu pobedničke.',
'bonus_launch': 'Osvojite X2 na svim opkladama u rundi „Bonus uzletanje“!',
'multiplier_history': 'Istorija multiplikatora',
'will_start_soon': 'uskoro će početi',
'hurry_up_and_place_your_bets': 'Požurite i uložite svoje opklade!',
'active_bets': 'Aktivne opklade',
'shop': 'poslovnica',
'loading': 'Učitavanje',
'lost_connection': 'Lost Connection',
'jackpot_winners': 'Jackpot Winners',
'round': 'Igra',
'round_id': 'Broj runde',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.zh = {
'bet_bets_tab': '投注额',
'coeff': '系数',
'win': '赢',
'game_explanation_1': '选择您的乘数，如果游戏乘数超过您选择的乘数，您的奖金将是',
'game_explanation_2': '您的乘数 x 您的赌注',
'retail_max_mp_1': '赢得最多',
'retail_max_mp_2': '你的赌注！',
'play_multibets': '玩',
'multibets': '多重投注',
'bonus_game': '獎勵遊戲',
'all_wins_doubled': '所有勝利都翻倍！',
'multibets_paragraph_1': '來增加你的勝算！',
'multibets_paragraph_2': '您可以將最多十輪的投注合併為一個多重投注，並贏得巨額獎金！',
'multibets_paragraph_3': '每贏一輪，該輪的贏利紅利將用於下一輪的投注，直到所有輪都獲勝。',
'bonus_launch': '在“奖金启动”轮中的所有投注中赢得 x2！',
'multiplier_history': '乘数历史',
'will_start_soon': '即将开始',
'hurry_up_and_place_your_bets': '赶快下注吧！',
'active_bets': '活跃投注',
'shop': '店铺',
'loading': '加载中',
'lost_connection': '失去连接',
'jackpot_winners': 'Jackpot Winners',
'round': '回合',
'round_id': '回合ID：',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.hi = {
'bet_bets_tab': 'दांव',
'coeff': 'गुणक',
'win': 'जीत',
'game_explanation_1': 'अपना गुणक चुनें, और यदि खेल गुणक आपके चुने हुए गुणक से अधिक हो जाता है, तो आपकी जीत होगी',
'game_explanation_2': 'आपका गुणक x आपका दांव',
'retail_max_mp_1': 'तक जीतें',
'retail_max_mp_2': 'आपका दांव!',
'play_multibets': 'खेल',
'multibets': 'मल्टीबेट्स',
'bonus_game': 'बोनस खेल',
'all_wins_doubled': 'सभी जीतें दोगुनी हो गईं!',
'multibets_paragraph_1': 'अपनी संभावनाएँ बढ़ाने के लिए!',
'multibets_paragraph_2': 'आप दस राउंड तक के दांवों को एक मल्टीबेट में जोड़ सकते हैं और भारी भुगतान जीत सकते हैं!',
'multibets_paragraph_3': 'हर बार जब एक राउंड जीता जाता है, तो उस राउंड से प्राप्त लाभांश को अगले राउंड पर दांव पर लगाया जाता है, जब तक कि सभी राउंड जीत नहीं जाते।',
'bonus_launch': '„बोनस लॉन्च” राउंड में सभी दांवों पर x2 जीतें!',
'multiplier_history': 'गुणक इतिहास',
'will_start_soon': 'जल्द ही शुरू होगा',
'hurry_up_and_place_your_bets': 'जल्दी करें और अपना दांव लगाएं!',
'active_bets': 'सक्रिय दांव',
'shop': 'दुकान',
'loading': 'लोड हो रहा है',
'lost_connection': 'कनेक्शन टूट गया',
'jackpot_winners': 'Jackpot Winners',
'round': 'राउंड',
'round_id': 'राउंड ID',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.cs = {
'bet_bets_tab': 'PODAT SÁZKU',
'coeff': 'KOEFICIENT',
'win': 'VÝHRA',
'game_explanation_1': 'Vyberte si multiplikátor, a pokud herní multiplikátor překročí vámi zvolený multiplikátor, vaše výhra bude',
'game_explanation_2': 'Váš multiplikátor x vaše sázka',
'retail_max_mp_1': 'Vyhrajte až',
'retail_max_mp_2': 'vaše sázka!',
'play_multibets': 'Hrát si',
'multibets': 'MULTIBETY',
'bonus_game': 'BONUSOVÁ HRA',
'all_wins_doubled': 'Všechny výhry se zdvojnásobily!',
'multibets_paragraph_1': 'zvýšit své šance!',
'multibets_paragraph_2': 'Můžete kombinovat sázky až na deset kol do jediné multibety a vyhrát masivní výplaty!',
'multibets_paragraph_3': 'Pokaždé, když je kolo vyhráno, vítězná dividenda z tohoto kola se vsadí na další kolo, dokud všechna kola nevyhrají.',
'bonus_launch': 'Vyhrajte 2x na všechny sázky v kole „Bonus Launch“!',
'multiplier_history': 'Historie multiplikátoru',
'will_start_soon': 'začne brzy',
'hurry_up_and_place_your_bets': 'Pospěšte si a sázejte!',
'active_bets': 'Aktivní sázky',
'shop': 'prodejna',
'loading': 'Načítání',
'lost_connection': 'Ztracené spojení',
'jackpot_winners': 'Jackpot Winners',
'round': 'Kolo',
'round_id': 'Id kola',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.id = {
'bet_bets_tab': 'JUMLAH TARUHAN',
'coeff': 'KOEF.',
'win': 'KEMENANGAN',
'game_explanation_1': 'Pilih pengganda Anda, dan jika pengganda permainan melebihi pengganda yang Anda pilih, kemenangan Anda akan tercapai',
'game_explanation_2': 'Pengganda Anda x Taruhan Anda',
'retail_max_mp_1': 'Menangkan hingga',
'retail_max_mp_2': 'taruhanmu!',
'play_multibets': 'Bermain',
'multibets': 'MULTIBET',
'bonus_game': 'BONUS PERMAINAN',
'all_wins_doubled': 'Semua kemenangan berlipat ganda!',
'multibets_paragraph_1': 'untuk meningkatkan peluang Anda!',
'multibets_paragraph_2': 'Anda dapat menggabungkan taruhan hingga sepuluh putaran menjadi satu multibet dan memenangkan pembayaran besar-besaran!',
'multibets_paragraph_3': 'Setiap kali suatu putaran dimenangkan, dividen kemenangan dari putaran tersebut dipertaruhkan pada putaran berikutnya, hingga semua putaran menang.',
'bonus_launch': 'Menangkan x2 pada semua taruhan di babak "Peluncuran Bonus"!',
'multiplier_history': 'Sejarah Pengganda',
'will_start_soon': 'akan segera dimulai',
'hurry_up_and_place_your_bets': 'Cepat dan pasang taruhanmu!',
'active_bets': 'Taruhan Aktif',
'shop': 'toko',
'loading': 'Memuat',
'lost_connection': 'Koneksi Hilang',
'jackpot_winners': 'Jackpot Winners',
'round': 'Ronde',
'round_id': 'Id Ronde',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.th = {
'bet_bets_tab': 'เงินเดิมพัน',
'coeff': 'อัตราต่อรอง',
'win': 'เงินรางวัล',
'game_explanation_1': 'เลือกตัวคูณของคุณ และหากตัวคูณของเกมเกินกว่าตัวคูณที่คุณเลือก คุณจะชนะ',
'game_explanation_2': 'ตัวคูณของคุณ x เดิมพันของคุณ',
'retail_max_mp_1': 'ชนะได้ถึง',
'retail_max_mp_2': 'เดิมพันของคุณ!',
'play_multibets': 'เล่น',
'multibets': 'มัลติเบต',
'bonus_game': 'เกมโบนัส',
'all_wins_doubled': 'ชัยชนะทั้งหมดเป็นสองเท่า!',
'multibets_paragraph_1': 'เพื่อเพิ่มโอกาสของคุณ!',
'multibets_paragraph_2': 'คุณสามารถรวมการเดิมพันได้มากถึงสิบรอบเป็นเดิมพันเดียวและชนะการจ่ายเงินก้อนโต!',
'multibets_paragraph_3': 'ในแต่ละรอบที่ชนะ การชนะเงินปันผลจากรอบนั้นจะถูกเดิมพันในรอบถัดไป จนกว่าทุกรอบจะชนะ',
'bonus_launch': 'ชนะ x2 จากการเดิมพันทั้งหมดในรอบ "เปิดตัวโบนัส"!',
'multiplier_history': 'ประวัติตัวคูณ',
'will_start_soon': 'จะเริ่มเร็ว ๆ นี้',
'hurry_up_and_place_your_bets': 'รีบวางเดิมพันของคุณ!',
'active_bets': 'เดิมพันที่ใช้งานอยู่',
'shop': 'ร้านค้า',
'loading': 'กำลังโหลด',
'lost_connection': 'การเชื่อมต่อขาดหาย',
'jackpot_winners': 'Jackpot Winners',
'round': 'รอบ',
'round_id': 'ID รอบ',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.tl = {
'bet_bets_tab': 'TAYA',
'coeff': 'COEFF.',
'win': 'PANALO',
'game_explanation_1': 'Piliin ang iyong multiplier, at kung ang multiplier ng laro ay lumampas sa iyong napiling multiplier, ang iyong panalo',
'game_explanation_2': 'Iyong Multiplier x Iyong Taya',
'retail_max_mp_1': 'Manalo hanggang sa',
'retail_max_mp_2': 'ang taya mo!',
'play_multibets': 'Maglaro',
'multibets': 'MGA MULTIBETS',
'bonus_game': 'Pōṉas viḷaiyāṭṭu',
'all_wins_doubled': 'aṉaittu veṟṟikaḷum iraṭṭippākiṉa!',
'multibets_paragraph_1': 'upang madagdagan ang iyong mga posibilidad!',
'multibets_paragraph_2': 'Maaari mong pagsamahin ang mga taya sa hanggang sampung round sa isang multibet at manalo ng malalaking payout!',
'multibets_paragraph_3': 'Sa tuwing nanalo ang isang round, ang panalong dibidendo mula sa round na iyon ay itataya sa susunod na round, hanggang sa manalo ang lahat ng round.',
'bonus_launch': 'Manalo ng x2 sa lahat ng taya sa round na "Bonus Launch"!',
'multiplier_history': 'Kasaysayan ng Multiplier',
'will_start_soon': 'magsisimula sa lalong madaling panahon',
'hurry_up_and_place_your_bets': 'Magmadali at ilagay ang iyong mga taya!',
'active_bets': 'Mga Aktibong Taya',
'shop': 'mamili',
'loading': 'Naglo-load',
'lost_connection': 'Nawalang Koneksyon',
'jackpot_winners': 'Jackpot Winners',
'round': 'Round',
'round_id': 'Round Id',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.xe = {
'bet_bets_tab': 'COINS',
'coeff': 'COEFF.',
'win': 'WIN',
'game_explanation_1': 'Choose your multiplier, and if the game multiplier exceeds your chosen multiplier, your win will be',
'game_explanation_2': 'Your Multiplier x Your Bet',
'retail_max_mp_1': 'Win up to',
'retail_max_mp_2': 'your bet!',
'play_multibets': 'Play',
'multibets': 'MULTIBETS',
'bonus_game': 'BONUS GAME',
'all_wins_doubled': 'All wins doubled!',
'multibets_paragraph_1': 'to increase your odds!',
'multibets_paragraph_2': 'You may combine bets on up to ten rounds into a single multibet and win massive payouts!',
'multibets_paragraph_3': 'Each time a round is won, winning dividend from that round is wagered on the next round, until all rounds are winning.',
'bonus_launch': 'Win x2 on all bets in the „Bonus Launch“ round!',
'multiplier_history': 'Multiplier History',
'will_start_soon': 'will start soon',
'hurry_up_and_place_your_bets': 'Hurry up and place your bets!',
'active_bets': 'Active Bets',
'shop': 'shop',
'loading': 'Loading',
'lost_connection': 'Lost Connection',
'jackpot_winners': 'Jackpot Winners',
'round': 'Round',
'round_id': 'Round Id',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.da = {
'bet_bets_tab': 'INDSATS',
'coeff': 'COEFF.',
'win': 'VINDE',
'game_explanation_1': 'Vælg din multiplikator, og hvis spilmultiplikatoren overstiger din valgte multiplikator, vil din gevinst være',
'game_explanation_2': 'Din multiplikator x din indsats',
'retail_max_mp_1': 'Vind op til',
'retail_max_mp_2': 'din indsats!',
'play_multibets': 'Spil',
'multibets': 'MULTIBETS',
'bonus_game': 'BONUSSPIL',
'all_wins_doubled': 'Alle sejre fordoblet!',
'multibets_paragraph_1': 'for at øge dine odds!',
'multibets_paragraph_2': 'Du kan kombinere indsatser på op til ti runder til et enkelt multibet og vinde massive udbetalinger!',
'multibets_paragraph_3': 'Hver gang en runde vindes, satses vinderudbytte fra den runde i næste runde, indtil alle runder vinder.',
'bonus_launch': 'Vind x2 på alle væddemål i "Bonus Launch"-runden!',
'multiplier_history': 'Multiplikator historie',
'will_start_soon': 'starter snart',
'hurry_up_and_place_your_bets': 'Skynd dig og placer dine indsatser!',
'active_bets': 'Aktive væddemål',
'shop': 'butik',
'loading': 'Indlæser',
'lost_connection': 'Tabt forbindelse',
'jackpot_winners': 'Jackpot Winners',
'round': 'Rund',
'round_id': 'Runde Id',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.nl = {
'bet_bets_tab': 'INZET',
'coeff': 'COEFF.',
'win': 'WINNEN',
'game_explanation_1': 'Kies uw vermenigvuldiger, en als de spelvermenigvuldiger de door u gekozen vermenigvuldiger overschrijdt, is uw winst',
'game_explanation_2': 'Uw vermenigvuldiger x uw inzet',
'retail_max_mp_1': 'Win tot',
'retail_max_mp_2': 'jouw weddenschap!',
'play_multibets': 'Toneelstuk',
'multibets': 'MULTIBETS',
'bonus_game': 'BONUSSPEL',
'all_wins_doubled': 'Alle overwinningen verdubbeld!',
'multibets_paragraph_1': 'om uw kansen te vergroten!',
'multibets_paragraph_2': 'Je kunt weddenschappen op maximaal tien ronden combineren tot één enkele multibet en enorme uitbetalingen winnen!',
'multibets_paragraph_3': 'Elke keer dat een ronde wordt gewonnen, wordt het winnende dividend uit die ronde ingezet in de volgende ronde, totdat alle rondes winnend zijn.',
'bonus_launch': 'Win x2 op alle weddenschappen in de “Bonus Launch” ronde!',
'multiplier_history': 'Vermenigvuldigingsgeschiedenis',
'will_start_soon': 'zal binnenkort beginnen',
'hurry_up_and_place_your_bets': 'Schiet op en plaats uw weddenschappen!',
'active_bets': 'Actieve weddenschappen',
'shop': 'winkel',
'loading': 'Bezig met laden',
'lost_connection': 'Verbinding verloren',
'jackpot_winners': 'Jackpot Winners',
'round': 'Ronde',
'round_id': 'Ronde ID',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
langArr.s1 = {
'bet_bets_tab': 'COINS',
'coeff': 'COEFF.',
'win': 'WIN',
'game_explanation_1': 'Choose your multiplier, and if the game multiplier exceeds your chosen multiplier, your win will be',
'game_explanation_2': 'Your Multiplier x Your Bet',
'retail_max_mp_1': 'Win up to',
'retail_max_mp_2': 'your bet!',
'play_multibets': 'Play',
'multibets': 'MULTIBETS',
'bonus_game': 'BONUS GAME',
'all_wins_doubled': 'All wins doubled!',
'multibets_paragraph_1': 'to increase your odds!',
'multibets_paragraph_2': 'You may combine bets on up to ten rounds into a single multibet and win massive payouts!',
'multibets_paragraph_3': 'Each time a round is won, winning dividend from that round is wagered on the next round, until all rounds are winning.',
'bonus_launch': 'Win x2 on all bets in the „Bonus Launch“ round!',
'multiplier_history': 'Multiplier History',
'will_start_soon': 'will start soon',
'hurry_up_and_place_your_bets': 'Hurry up and place your bets!',
'active_bets': 'Active Bets',
'shop': 'shop',
'loading': 'Loading',
'lost_connection': 'Lost Connection',
'jackpot_winners': 'Jackpot Winners',
'round': 'Round',
'round_id': 'Round Id',
'type': 'Type',
'amount': 'Amount',
'time': 'Time',
'ticket_id': "Ticket ID" ,
};
