import WindowBridgeAbstract from "./WindowBridgeApstract";

class R7RGSWindowBridge extends WindowBridgeAbstract {

  /**
   * 
   * @param {*} val 
   */
  use7RGSWintegration(val) {
    this.useIntegration = val;
  }

  /**
   * 
   * @param {*} balance 
   */
  gameLoaded(balance) {
    this.postMessage({ "event": "loaded", "balance": Math.round(balance)});
  }

  /**
   * 
   * @param {*} balance 
   * @param {*} myBetsByRound 
   * @returns 
   */
  roundStart(balance, myBetsByRound, currRound) {
    if (!this.useIntegration) return;
    if (!myBetsByRound) return;
    if (!myBetsByRound.length) return;
    
    try {
      const filteredCurrRoundBets = myBetsByRound.filter(val => {
        if (val.round_id == currRound) {
          return val;
        }
      });

      if (!filteredCurrRoundBets.length) return;

      const totalBetAmount = filteredCurrRoundBets.length === 1 ? filteredCurrRoundBets[0].bet : filteredCurrRoundBets.reduce((acc, currentval) => acc.bet + currentval.bet);
      this.postMessage({ "event": "round_start", "balance": Math.round(balance), "bet": Math.round(totalBetAmount)});
    } catch (error) {
      console.error("Cannot post event round_start, reason : ", error);
    }
  }

  /**
   * 
   * @param {*} balance 
   * @param {*} myBetsByRound 
   * @param {*} currRound 
   * @returns 
   */
  roundResult(balance, myBetsByRound, currRound, jackpotValue) {
    if (!this.useIntegration) return;
    if (!myBetsByRound) return;
    if (!myBetsByRound.length) return;

    try {
      // filter samo za rundu tiketa
      const filteredCurrRoundBets = myBetsByRound.filter(val => {
        if (val.round_id == currRound) {
          return val;
        }
      });
      
      if (!filteredCurrRoundBets.length) return;
      const totalBetAmount = filteredCurrRoundBets.length === 1 ? filteredCurrRoundBets[0].bet : filteredCurrRoundBets.reduce((acc, currentval) => acc.bet + currentval.bet);
      const totalWinAmount = filteredCurrRoundBets.length === 1 ? filteredCurrRoundBets[0].win : filteredCurrRoundBets.reduce((acc, currentval) => acc?.win + currentval?.win);
      if(jackpotValue > 0) {
        this.postMessage({ "event": "round_result", "balance": Math.round(balance), "bet": Math.round(totalBetAmount), "win": Math.round(totalWinAmount) || 0, "jackpot_win": Math.round(jackpotValue) });
      }
      else {
      this.postMessage({ "event": "round_result", "balance": Math.round(balance), "bet": Math.round(totalBetAmount), "win": Math.round(totalWinAmount) || 0 });
      }
    } catch (error) {
      console.error("Cannot post event round_result, reason : ", error);
    }
  }

  /**
   * 
   * @param {*} balance 
   */
  roundComplete(balance) {
    this.postMessage({ "event": "round_complete", "balance": Math.round(balance)});
  }

  /**
   * 
   * @param {*} balance 
   */
  outOfCredits(balance) {
    this.postMessage({ "event": "out_of_credits", "balance": Math.round(balance)});
  }

  /**
   * 
   * @param {*} freeBetsCount 
   */
  freeSpinStart(freeBetsCount) {
    this.postMessage({ "event": "free_spin_start", "count":freeBetsCount})
  }

  /**
   * 
   * @param {*} winAmount 
   */
  freeSpinEnd(winAmount) {
    this.postMessage({ "event": "free_spin_end", "win": Math.round(winAmount)});
  }

  bonusGameStart () {
    this.postMessage({ "event": "bonus_game_start"});
  }

  bonusGameEnd (winAmount) {
    this.postMessage({ "event": "bonus_game_end", "win": Math.round(winAmount)});
  }

  /**
   * 
   * @param {*} val 
   */
  toggleSFX(val) {
    this.postMessage({ "event": "audio_sfx", "state": val});
  }

  /**
   * 
   * @param {*} val 
   */
  toggleMusic(val) {
    this.postMessage({ "event": "audio_music", "state": val});
  }
}

export default R7RGSWindowBridge;
