import React, { useState } from 'react'
import RetailTotalBetsBadge from './RetailTotalBetsBadge'
import RetailRoundId from './RetailRoundId'
import AllShopsTab from '../bets-tabs/AllShopsTab'
import RetailNow from './RetailNow'
import { RetailContextProvder } from '../../contexts/RetailTickets'
import RetailBonusRound from './RetailBonusRound'

export default function MainRetail() {
  const [retailBonusRound, setRetailBonusRound] = useState(10);

  window.setRetailBonusRound = setRetailBonusRound;

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <div className={"desktop-background retail"}></div>
      <div className={"container container-retail"}>
        <div className={"desktop-container retail "}>
          <div className={"desktop-left retail "}>
            <div className="desktop-section-title">
             <RetailTotalBetsBadge />
            </div>
            <div className="position-relative desktop-left-height">
            <div className="pod-tabs-panel-holder container">
            <RetailContextProvder>
              <AllShopsTab />
            </RetailContextProvder>
              <div className="desktop-left-bottom-background"></div>
            </div>
            </div>
          </div>
          <div className={"desktop-middle retail"}>
            <RetailRoundId />
            <div
              className={"desktop-dialog"}
            >
              <div className="desktop-bet-info-holder">
              </div>
            </div>
            {retailBonusRound ? <RetailBonusRound /> : null}
            <RetailNow />
          </div>
        </div>
      </div>
    </div>
  )
}
