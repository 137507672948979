import React from "react";
import avatars from "../model/avatars";
import Flag from "../components/Flag/Flag";
import rocketmanTransport from "../services/RocketmanTransport";

const JackpotTableTr = (props) => {
  return (
    <tr className={"all-bets-tr " + (rocketmanTransport.r7css ? "r7css" : "")}>
      <td>{props.date}</td>
      <td>{props.time}</td>
      <td>{props.jackpotWinAmount}</td>
      <td>
        <div className="all-bets-td-holder">
          <div className="all-bets-tr-avatar">
            <img src={avatars[props.avatar]} alt="" />
          </div>
          <span className="all-bets-tr-username">
            {props.username}
            {props.isVip ? (
              <img
                className="table-vip-avatar-marker"
                src="/svg/crown.svg"
                alt="crown"
              />
            ) : null}
          </span>
          <div className="all-bets-tr-flag">
            {props.flagCode === "XS" ? (
              <Flag className="xs-flag" code={"RU"} height="10" width="14" />
            ) : (
              <Flag code={props.flagCode} height="10" width="14" />
            )}
          </div>
        </div>
      </td>
    </tr>
  );
};

export default JackpotTableTr;
