import React from 'react'
import translate from '../../services/Translation/lang';

export default function RetailBonusRound() {

  return (
    <div className="retail-bonus-round">
        <span>{translate('bonus_game')}</span>
        <span className='small'>{translate('all_wins_doubled')}</span>
    </div>
  )
}