import React, { useMemo } from "react";
//import Currency from "./Currency/Currency";
import WinTableCol from "./WinTableCol";
import RetailColUsername from "./Retail/RetailTableCol/RetailColUsername";
import RetailColFlag from "./Retail/RetailTableCol/RetailColFlag";
import RetailBetCol from "./Retail/RetailTableCol/RetailBetCol";
import RetailCoifficientCol from "./Retail/RetailTableCol/RetailCoifficientCol";
import { highLightRetailCoef } from "../services/Helpers/RetailHighLight";

const AllBetsTrRetail = (props) => {
  const highlightClass = highLightRetailCoef(props.coefficient);
  return (
    <tr className={`all-bets-tr`}>
      <td>
        <div className="all-bets-td-holder">
            <RetailColUsername username={props.username} isVip={props.isVip} />
          {useMemo(() => ( <RetailColFlag flagCode={props.flagCode} /> ),[props.flagCode])}
        </div>
      </td>
      <td>
        <RetailBetCol bet={props.bet} />
        <span>{props.valute === "ETBB" ? "ETB" : props.valute}</span>
      </td>
      <td>
        <span>
          <span
            className={`all-bets-tr-coefficient all-bets-tr-coefficient-retail ${highlightClass}`}
          >
            {useMemo(()=> (<RetailCoifficientCol coefficient={props.coefficient} />),[props.coefficient])}
          </span>
        </span>
      </td>
      {useMemo(() => ( <WinTableCol win={props.win} index={props.index} valute={props.valute} />),[props.index,props.win,props.valute])}
    </tr>
  );
}

export default AllBetsTrRetail;