
/**
 * LocalStorage
 */
class LocalStorage {

  set(key,valueToStore) {
    try {
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log("There was an error storing " + key + "in local storage :" + error);
    }
  }

  get(key) {
    try {
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : null;
    } catch (error) {
      console.log("There was an getting key " + key + "from local storage :" + error);
    }
  }

  remove(key) {
    try {
      window.localStorage.removeItem(key);
    } catch (error) {
      console.log("There was an getting key " + key + "from local storage :" + error);
    }
  }

  findLike (query) {
    let i, results = [];
    for (i in localStorage) {
      if (localStorage.hasOwnProperty(i)) {
        if (query && i.match(query)) {
          const  value = JSON.parse(localStorage.getItem(i));
          results.push({key:i,val:value});
        }
      }
    }
    return results;
  }
}

export default LocalStorage;
