import React from "react";
import rocketmanTransport from "../../services/RocketmanTransport";
import { useState } from "react";
import { useEffect } from "react";


export default function Currency({children , className}) {
const [showCurrency, setShowCurrency] = useState(rocketmanTransport.isCurrencyVisible);

useEffect(() => {
    setShowCurrency(rocketmanTransport.isCurrencyVisible)
}, [rocketmanTransport.isCurrencyVisible])

  return showCurrency ? (
    <span className={className}>{children === "ETBB" ? children = "ETB" : children}</span>
  ) : null;
}
