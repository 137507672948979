import React from 'react'
// import Flag from 'react-world-flags'
import Country from "flagit";

export default function RetailColFlag(props) {
  return (
    <div className="all-bets-tr-flag">
      {props.flagCode === "XS" ? (
        <span  style={{ transform: 'rotate(180deg)', display: "flex" }}>
           <Country className="xs-flag"  countryShort={"RU"} />
        </span>
       
      ) : (
        <Country countryShort={props.flagCode.toUpperCase()}/>
      )}
    </div>
)
}
