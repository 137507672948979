import rocketmanTransport from "../RocketmanTransport";

export const filterIsolatedBets = (bet) => {
  if (rocketmanTransport.isolated) {
    if (Number(bet.company_id) === Number(rocketmanTransport.launcherCompanyId)) return true;
  } else {
    if (!rocketmanTransport.isolatedList.includes(String(bet.company_id))) return true;
  }

  return false;
}
