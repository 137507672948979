import React from 'react';
import ElbetLogoLoader from '../svg/elbet-logo-loader';
//import RetailGif from '../../img/retail-slider/retail-gif.gif';

export default function RocketmanSlider() {
  return (
    <div className='slide rocketman-slide'>
      <ElbetLogoLoader width={192} height={190}/>
      {/* <img src={RetailGif} alt="retail-gif" className='rocketman-slider-gif' /> */}
      <p>Rocketman</p>
    </div>
  )
}
