import React from 'react';
import rocketmanTransport from '../../../services/RocketmanTransport';
import { useRocketmanTransport } from '../../rocketman/RocketmanTransportContext';
import Currency from '../../Currency/Currency';
import JackpotOdometer from '../../JackpotOdometer';

export default function JackpotHolder() {

  const rocketmanContext = useRocketmanTransport();
  const jackpotData = rocketmanContext.jackpotData;

  return (
    <div className="jackpot-cash-holder">
      {rocketmanTransport.retail ? <span style={{ marginBottom: -6}} className='jackpot-title-retail mr-2'>JACKPOT</span> : null}<JackpotOdometer value={Number(Number(jackpotData.jackpot)).toFixed(rocketmanTransport.noOfDecimals)} format={2} duration={60} />  <Currency className={"jackpot-currency " + (rocketmanTransport.r7css ? "r7css" : "")}>{rocketmanTransport.myCurrency}</Currency>
    </div>
  )
}
