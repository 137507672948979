import React from 'react';
import translate from '../../services/Translation/lang';

export default function BonusLaunch() {
  return (
    <div className='slide bonus-launch'>
      <p className='slider-text-big'>{translate('bonus_launch')}</p>
    </div>
  )
}
